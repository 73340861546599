<style scoped>
.form-param {
  border: 1px solid #ccc;
  padding: 10px;
}
.form-param label {
  font-size: 0.8rem;
}
</style>

<template>
  <modal name="parametroEdit" height="auto" width="600" :scrollable="true" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <h2 class="title">
          Alterar {{ descSingular }} <br>
          <small class="font-sm">Chave: {{ parametro.chave }}</small>
        </h2>
        <div class="form">
          <div class="form-single">
            <div class="xrow">
              <div class="col">
                <div class="form-group">
                  <label for>{{parametro ? parametro.desc : ''}}</label>
                  <div class="form-group form-param">
                  <label for>Valor padrão (utilizado quando não possui valor na filial)</label>
                  <TipoInput
                    v-model="parametro.valor"
                    :tipo="parametro.tipo"
                    :opcoes="parametro.opcoes"
                  ></TipoInput>
                  </div>
                  <div v-show="exibeValoresFiliais" v-for="(filial, index) in filiais" class="form-group form-param">
                    <label for>Filial {{filial.id}} - {{filial.razSoc}}</label>
                    <TipoInput
                      v-if="exibeValoresFiliais"
                      v-model="parametro.filiais[filial.id]"
                      :tipo="parametro.tipo"
                      :opcoes="parametro.opcoes"
                    ></TipoInput>
                  </div>
                  <button
                    type="button"
                    class="button button-primary"
                    style="margin-top: 10px"
                    v-on:click="adicionarValoresFiliais"
                  >
                    {{exibeValoresFiliais ? `Manter apenas valor padrão` : `Adicionar valores por filial`}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
          :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }"
          v-on:click="update"
          :disabled="validation.hasError()"
        >Atualizar</button>
        <button class="button" v-on:click="close">Fechar</button>
      </div>
    </div>
  </modal>
</template>
<script>
import { Validator } from "simple-vue-validator";
import TipoInput from "./TipoInput";
import _isEmpty from "lodash/isEmpty";
import CloseModal from "../../components/CloseModal";

export default {
  name: "parametroEdit",
  components: { TipoInput, CloseModal },
  props: ["update", "close", "parametro", "descSingular", "filiais"],
  data() {
    return {
      exibeValoresFiliais: 0,
    };
  },
  watch: {
    "parametro.chave"() {
      this.exibeValoresFiliais = !_isEmpty(this.parametro.filiais);
    },
  },
  methods: {
    adicionarValoresFiliais() {
      this.exibeValoresFiliais = !this.exibeValoresFiliais;

      if (!this.exibeValoresFiliais) {
        this.parametro.filiais = {};
      }
    },
  },
  validators: {
    "parametro.valor": function(value) {
      return this.parametro.tipo !== "TEXT" && this.parametro.tipo !== "IMAGE"
        ? Validator.value(value).required()
        : Validator.value(value);
    },
  },
};
</script>
