<template>
  <div>
    <Edit
        :parametro="currentParam"
        :update="updateParam"
        :close="closeEdit"
        :descSingular="descSingular"
        :filiais="filiais"
    ></Edit>
    <ModalInfoMarkdown
        :titulo="currentParam.desc"
        :conteudo="currentParam.documentacao"
        :close="closeInfo"
    ></ModalInfoMarkdown>
    <Loading :isActive="loading"></Loading>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab :title="descPlural">
        <div class="filters row" style="margin-top:0">
          <div class="col-1-2">
                  <span class="filters__search">
                    <input
                        type="text"
                        v-model="termo"
                        placeholder="Filtrar parametros por ..."
                    >
                    <img
                        style="position: relative; right: -10px;"
                        src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwNDE2ZiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
                    >
                  </span>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
            <tr>
              <th>Parâmetro</th>
              <th style="width: 200px"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="parametro in listaParametros" v-bind:key="parametro.chave">
              <td>
                {{ parametro.desc }}
                <small>{{ parametro.valor | tipo(parametro.tipo, parametro.opcoes) }}</small>
              </td>
             
              <td class="actions">
                <CButton
                    size="sm"
                    v-show="parametro.has_documentacao"
                    color="info"
                    @click="openInfo(parametro)" title="Informações detalhadas">
                  <fa-icon icon="info-circle"/>
                </CButton>
                <CButton color="primary" size="sm" @click="openEdit(parametro)">
                  <fa-icon icon="pencil-alt"/>
                </CButton>
                <LogAlteracaoDados
                  tabela="parametro_geral"
                  :chave="parametro.chave"
                />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import clone from 'just-clone';
import {get, put} from '../../helpers/apiRequest';
import {toDecimal, toMonetary, arrayFilter} from '../../helpers/utils';
import Edit from './Edit';
import ModalInfoMarkdown from '@/components/ModalInfoMarkdown';
import _orderBy from 'lodash/orderBy';
import {loadCargas} from '../../helpers/cargas';
import config from '../../config';

export default {
  name: 'parametros',
  components: {
    Edit,
    ModalInfoMarkdown,
  },
  data() {
    return {
      termo: '',
      loading: false,
      currentParam: {},
      parametros: [],
      filiais: [],
    };
  },
  computed: {
    listaParametros() {
      return arrayFilter(this.parametros, this.termo, ['desc', 'chave']);
    },
  },
  filters: {
    tipo(valor, tipo, opcoes) {
      switch (tipo) {
        case 'CURRENCY':
          return toMonetary(valor);

        case 'PERCENT':
          return toDecimal(valor);

        case 'SELECT':
          return opcoes[valor] || valor;

        case 'IMAGE':
        case 'BOOLEAN':
          if (valor) return 'Sim';
          return 'Não';

        default:
          return valor;
      }
    },
  },
  methods: {
    loadDescricaoModulo() {
      const {modulos} = JSON.parse(localStorage.getItem('app'));
      const {descPlural, descSingular} = modulos.find(
          (m) => m.nome === 'ADMIN_PARAMETRO'
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    openInfo(parametro) {
      this.currentParam = clone(parametro);
      this.loadParametroDetalhes();
      this.$modal.show('modalInfoMarkdown');
    },
    closeInfo() {
      this.$modal.hide('modalInfoMarkdown');
    },
    openEdit(parametro) {
      this.currentParam = clone(parametro);
      this.$modal.show('parametroEdit');
    },
    closeEdit() {
      this.$modal.hide('parametroEdit');
    },
    loadParametroDetalhes() {
      this.loading = true;

      get(`/admin/parametros/${this.currentParam.chave}`)
          .then((json) => {
            this.currentParam = json;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    loadParametros() {
      this.loading = true;
      get('/admin/parametros')
          .then((json) => (this.parametros = json))
          .catch(() => (this.parametros = []))
          .then(() => (this.loading = false))
          .catch(() => (this.loading = false));
    },
    updateParam() {
      const param = {
        chave: this.currentParam.chave,
        valor: this.currentParam.valor,
        filiais: this.currentParam.filiais,
      };

      loadCargas(
          config.token(),
          () => {
            localStorage.setItem('revalidar_carga', 0);
          }
      );

      this.loading = true;
      put(`/admin/parametros`, param)
          .then((json) => {
            this.loadParametros();
            this.$swal({
              title: 'Salvo com sucesso',
              text: json.message,
              showCancelButton: false,
              cancelButtonText: 'Fechar',
              showConfirmButton: true,
              icon: 'success',
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$swal({
              title: 'Falha ao Salvar',
              text: error.message,
              showCancelButton: true,
              cancelButtonText: 'Fechar',
              showConfirmButton: false,
              icon: 'error',
            });
          })
          .then(() => (this.loading = false))
          .then(() => this.closeEdit());
    },
  },
  beforeMount() {
    this.loadParametros();
    this.loadDescricaoModulo();
    const {filiais} = JSON.parse(localStorage.getItem('app'));
    this.filiais = _orderBy(filiais, 'id');
  },
};
</script>
