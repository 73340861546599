<template>
    <div v-if="tipo === 'SELECT'">
      <select class="form-control" v-bind:value="value" v-on:input="handleChange">
        <option v-for="(chave, valor) in opcoes" v-bind:value="valor" v-bind:selected="`${value}` === `${valor}`">
          {{ chave }}
        </option>
      </select>
    </div>

    <div v-else-if="tipo === 'NUMERIC'">
      <input type="number" class="form-control" v-bind:value="value" v-on:input="handleChange"/>
    </div>

    <div v-else-if="tipo === 'CURRENCY'">
      <money class="form-control" v-bind:value="value" v-on:input="handleChange" v-bind="monetary"></money>
    </div>

    <div v-else-if="tipo === 'PERCENT'">
      <money class="form-control" v-bind:value="value" v-on:input="handleChange" v-bind="percent"></money>
    </div>

    <div v-else-if="tipo === 'BOOLEAN'">
      <select class="form-control" v-bind:value="value" v-on:input="handleChange">
        <option value="1" v-bind:selected="+value === 1">Sim</option>
        <option value="0" v-bind:selected="+value === 0">Não</option>
      </select>
    </div>

    <div v-else-if="tipo === 'IMAGE'">
        <image-picker
            placeholder="Colar URL ou clique ao lado para upload"
            v-bind:value="value"
            v-on:input="handleChange" />
        <img v-if="value" :src="value" alt="Preview" class="thumbnail thumbnail-small"/>

    </div>

    <div v-else>
      <input type="text" class="form-control" v-bind:value="value" v-on:input="handleChange"/>
    </div>

</template>

<script>
import ImagePicker from "../../components/ImagePicker";

export default {
  name: 'tipoInput',
  components: {
    ImagePicker
  },
  props: [
    'value',
    'tipo',
    'opcoes'
  ],
  data() {
    return {
      monetary: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        masked: false
      },
      percent: {
        decimal: ',',
        thousands: '.',
        suffix: '% ',
        masked: false
      },
    }
  },
  methods: {
    handleChange(event) {
      if (event instanceof Object) {
        this.$emit('input', event.target.value)
      } else {
        this.$emit('input', event)
      }
    }
  }
}
</script>
